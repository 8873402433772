import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import './WidgetBottom.scss'
import { Icon } from '../../UI/Icon'

const cnWidgetBottom = cn('WidgetBottom')

interface WidgetBottomProps {
  className?: string
}

export const WidgetBottom: FC<WidgetBottomProps> = ({ className }) => {
  const { t } = useTranslation('translation')

  return (
    <div className={cnWidgetBottom(null, [className])}>
      <div className={cnWidgetBottom('list')}>
        <div className={cnWidgetBottom('item')}>
          <div className={cnWidgetBottom('key')}>
            {t('footer-merchant')}

            <Icon name='info' />
          </div>
          <div className={cnWidgetBottom('value')}>Сryptoicons.net</div>
        </div>
        <div className={cnWidgetBottom('item')}>
          <div className={cnWidgetBottom('key')}>
            {t('footer-invoice-id')}
            <Icon name='info' />
          </div>
          <div className={cnWidgetBottom('value')}>INV-LHKIFP81</div>
        </div>
        <div className={cnWidgetBottom('item')}>
          <div className={cnWidgetBottom('key')}>
            {t('footer-fee')}
            <Icon name='info' />
          </div>
          <div className={cnWidgetBottom('value')}>0.001 ETH</div>
        </div>
      </div>
    </div>
  )
}
