import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'

import { ThemeProvider } from './core/ThemeProvider'
import { store } from './core/store'
import i18n from './core/i18n'

import { WidgetTop } from './components/Layout/WidgetTop'
import { WidgetBottom } from './components/Layout/WidgetBottom'

import './App.scss'

export const App = () => {
  return (
    <div className='App'>
      <ThemeProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <div className='App-container'>
              <div className='App-center'>
                <WidgetTop />
              </div>
              <WidgetBottom />
            </div>
          </I18nextProvider>
        </Provider>
      </ThemeProvider>
    </div>
  )
}
