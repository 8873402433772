import { cn } from '@bem-react/classname'
import { FC } from 'react'

import './WidgetTop.scss'

const cnWidgetTop = cn('WidgetTop')

interface WidgetTopProps {
  className?: string
}

export const WidgetTop: FC<WidgetTopProps> = ({ className }) => {
  return (
    <div className={cnWidgetTop(null, [className])}>
      <img src='/img/cryptocopy.svg' alt='cryptocopy' />
    </div>
  )
}
