import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { ThemeType } from '@/core/types'

interface AppState {
  theme: ThemeType
  paymentProgress: any | null
}

const initialState: AppState = {
  theme: 'light',
  paymentProgress: null,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeTheme(state, action: PayloadAction<ThemeType>) {
      state.theme = action.payload
    },

    setPaymentProgressData(state, action: PayloadAction<any>) {
      state.paymentProgress = action.payload
    },
  },
})

export const { actions, reducer } = appSlice
export const { changeTheme, setPaymentProgressData } = actions
